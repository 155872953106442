import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const allData = [
  {
    id: 1,
    text: "Our Java skills are second to none, and we have the experience to back them up.",
  },
  {
    id: 2,
    text: "We've worked in 30 industries (manufacturing, healthcare, eCommerce, banking, telecommunications, and more) and we're familiar with all the latest tech (e.g., AI/ML, AR/VR, blockchain, big data, cloud computing).",
  },
  {
    id: 3,
    text: "So whatever your needs are when it comes to Java development - code review and maintenance, new app planning, custom development, legacy migration - we can help.",
  },
  {
    id: 4,
    text: "You need to be able to make decisions quickly and sometimes they don't have the time to fully evaluate their options. In these cases, a fixed-price contract can be the best option.",
  },
  {
    id: 5,
    text: "With a fixed-price contract, you pay the price established by the contract upfront. This type of contract is best for Java project feasibility studies, PoCs, and small Java projects with clear and stable requirements.",
  },
  {
    id: 6,
    text: "If you need more flexible pricing, you can consider a Time &amp; Material or Time &amp; Material with a cap contract.",
  },
  {
    id: 7,
    text: "With these types of contracts, you pay based on the hours or efforts reported per month. This can be a good option for advisory activities, agile Java app development, and implementation, or Java app evolution.",
  },
  {
    id: 8,
    text: "Per-ticket pricing is best for Level 1 and 2 Java application support. With this type of pricing, we will agree on a ticket cost at the beginning of our partnership, and you will be charged monthly based on the number of incidents that we resolve.",
  },
  {
    id: 9,
    text: "The fixed monthly fee is best for Level 3 Java application support, which includes in-code defect fixes and functional changes.",
  },
];

const Java = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Java Development Company | Origin software</title>
          <link rel="canonical" href="./Flutter-App-Development-Company" />
          <meta
            name="description"
            content="Java Development Company which is Originsoftwares delivers the cost effective and high-quality custom java applications to its clients."
          />
          <meta name="keywords" content="java development company" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Technologies"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Java-Development-Company"
                                  }
                                >
                                  Java
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Node-Js-Development-Services"
                                  }
                                >
                                  Node.Js
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Hire-Python-Developers"
                                  }
                                >
                                  Python
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/IOS-App-Development-Services"
                                  }
                                >
                                  iOS
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Andriod-App-Development-Services"
                                  }
                                >
                                  Android
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Angular-Development-Services"
                                  }
                                >
                                  Angular
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Flutter-App-Development-Company"
                                  }
                                >
                                  Flutter
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Dot-Net-Application-development"
                                  }
                                >
                                  .Net
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Php-Development-Services"
                                  }
                                >
                                  PHP
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/React-js-development-services"
                                  }
                                >
                                  React.Js
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Magento-Development-Services"
                                  }
                                >
                                  Magento
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/WordPress-Development-Company-in-India"
                                  }
                                >
                                  WordPress
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Java Development Company</h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/Java.png"
                    alt="Java-Development-Company"
                  ></img>
                  <p className="mb--20">
                    If you're looking for a Java development company to help
                    with your java enterprise apps, software products, or SaaS
                    solutions, consider Origin Softwares. We've been helping
                    clients build sustainable Java-based software for over 9
                    years, and our team of 10 developers has a wealth of
                    experience to draw on. <br></br>
                    <br></br>
                    We're also committed to quality,{" "}
                    <span style={{ fontWeight: "bold" }}>
                      with over 10 Java developers on our team and an average
                      experience of 9 years.
                    </span>
                  </p>

                  <h4 className="mb--20">
                    Sample of the Java Solutions We Offer
                  </h4>
                  {/* <p>Add the image here with java processes</p> */}

                  <div className="row">
                    <h4>Opt for Risk-Free, Expert Java Development</h4>
                    {allData.slice(0, 3).map((item) => (
                      <div className="Box1 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <br />

                  <h4 className="mb--20">
                    Hard and Soft Skills Origin Softwares Excels At
                  </h4>
                  <div className="row">
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <h4 className="mb--20">Meet Our Java Expert</h4>
                  {/* <p>Add one of the senior java expert details here</p> */}

                  <h4 className="mb--20">
                    We Have a Track of Successful Projects in Various Industries
                  </h4>
                  <div className="row">
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <div className="row">
                    <div className="paper col-xl-12 col-lg-2">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <h4 className="mb--20">Choose Your Service Option</h4>
                          <p>
                            <ul className="b">
                              <li>
                                If you're looking to add some Java firepower to
                                your in-house team, then you have a few options.
                              </li>
                              <li>
                                You can hire a dedicated Java developer or team,
                                get a self-managed team that works in
                                collaboration with your other vendors, or hand
                                over the full Java project. With the full Java
                                project option, we take care of everything from
                                project planning to programming and app support.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb--20">
                    <h4>
                      Pricing Options for Java Software Development Services
                    </h4>
                    {allData.slice(3, 9).map((item) => (
                      <div className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p className="mb--20">
                    With this type of pricing, we will agree on an hourly rate
                    for Java app support activities at the beginning of our
                    partnership. After the service is delivered, you will pay
                    for a set number of hours that we have provided support.
                  </p>

                  <p>
                    <h4 className="mb--20">Selected Projects</h4>
                    <ul className="b">
                      <li>
                        At our company, we provide fast and efficient Java
                        application development services.
                      </li>
                      <li>
                        Our team of skilled developers are experts in popular
                        programming languages, especially java programming
                        language, and can help you get your java platform
                        project done quickly, safely, and within budget. Contact
                        us today to learn more about how we can help you with
                        your next Java development project.
                      </li>
                    </ul>
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Get A Quote
                    </Link>
                    <br></br>
                  </p>

                  <Accordion>
                    <h4 className="color">Java FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="What do Our Java Development Services Cover?"
                      body={
                        <>
                          <ul>
                            ► Our Java development services cover a wide range
                            of needs, from building secure and high-performing
                            web applications to creating cloud-native and
                            serverless apps.<br></br>► We also specialize in
                            Java-based SaaS application development, which
                            allows us to build secure SaaS products that can be
                            released quickly and easily to meet the needs of
                            clients.
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header="What do We build in Java?"
                      body={
                        <>
                          <ul>
                            ► Java-based enterprise applications, web services
                            portals, eCommerce websites, and self-service
                            network applications.<br></br>► There are many
                            different types of Java-based applications that are
                            built for a variety of purposes. Some of these
                            applications include enterprise systems,
                            industry-specific programs, and web portals.
                            <br></br>► In addition, there are also Java-based
                            products that are designed for self-service desktop
                            applications and multiplayer games.
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="3"
                      header="What Makes Origin Softwares Java Developers Stand Out?"
                      body={
                        <>
                          <ul>
                            ► What makes Origin Softwares Java developers stand
                            out is their ability to build future-proof app
                            architectures. <br></br>► They are also able to
                            deliver fast and stable Java apps by employing
                            mature Agile and DevOps practices. <br></br>►
                            Additionally, they offer cooperation flexibility by
                            teaming up with other specialists or experts as
                            needed, and can also self-manage projects from start
                            to finish.<br></br>► Having an in-house project
                            management office can be extremely beneficial for
                            larger, complex projects with distributed teams and
                            multiple vendors.<br></br>► Transparency and
                            communication are key to a successful project. Our
                            PMs are experienced and excel in providing these
                            services to our clients. <br></br>► We will monitor
                            and share the results of KPIs with you, including
                            business outcomes, quality of delivered software,
                            code quality, development process efficiency,
                            project time, and costs.<br></br>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="4"
                      header="What do Our Customers say?"
                      body={
                        <>
                          <ul>
                            ► “ Really appreciate Origin Software Team. I’ve
                            worked with quite a few SEO firms and so far,
                            they’ve done the best. Thanks for supporting my
                            business as I needed.” -Nouman Dawer<br></br>
                            <br></br>► “ The team has put in extraordinary
                            efforts to finish the project and helped our
                            business grow fast. Very focused and dedicated
                            collaboration. Thanks.” -Mohammed Jawad<br></br>
                            <br></br>► “ One of the finest organisation have
                            been associated with in the recent times energetic
                            with full young enthusiastic team keep it up guys.”
                            -Owais Shamsuddin<br></br>
                            <br></br>► “ One of the finest organization to
                            collaborate with very awesome back hand team to
                            support go a head team origin good luck.” -Farha
                            Ansari
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="5"
                      header="How We Develop Java Applications?"
                      body={
                        <>
                          <ul>
                            <h6> For enterprises</h6>► Enterprises need to be
                            aware of the discovery process, which can last 1-3
                            months. This is the time when you explore your
                            specific business needs, document key processes, and
                            consider relevant regulatory constraints.<br></br>►
                            You will also gather and process opinions and
                            expectations of all stakeholders, and structure and
                            prioritize the requirements for future software
                            based on business value.<br></br>► Finally, you will
                            analyze the risks of introducing a new software
                            system/operating system to the existing IT
                            infrastructure.<br></br>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="6"
                      header="How We Start and Organize Cooperation?"
                      body={
                        <>
                          <ul>
                            <h6>Staff Augmentation:</h6>► We understand that you
                            need staff augmentation to get the job done right.
                            That's why we take care of finding the right Java
                            developers for you, based on your specific needs and
                            requirements.<br></br>► We schedule interviews and
                            handle all the negotiations, so you can focus on
                            getting the job done.<br></br>► Plus, we're always
                            available to provide performance reports and
                            feedback, so you can be confident that your project
                            is on track.<br></br>
                            <br></br>
                            <h6>
                              Dedicated Java team/Full Java project outsourcing
                            </h6>
                            ► At Java Team, we take your RFPs seriously. We'll
                            send you a comprehensive proposal with technical
                            solutions and development approaches estimated.
                            <br></br>► On-demand, we'll negotiate SLAs and
                            suggest project and software KPIs. If everything
                            looks good on both ends, we'll sign the contract and
                            get to work setting up the development process and
                            implementing CI/CD (if needed).<br></br>► We might
                            also need to integrate with other vendors' processes
                            (if needed). You can transfer responsibility for the
                            project/project part to us with confidence that
                            we'll keep all stakeholders updated with regular
                            status reports.<br></br>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="7"
                      header="How We keep up with Java Code Quality?"
                      body={
                        <>
                          <ul>
                            ► There are a few key things to keep in mind when it
                            comes to coding: following the style guide, creating
                            descriptive names, leaving comments, documenting
                            code dependencies and functions, and keeping code
                            portable.<br></br>► Additionally, it's important to
                            practice code review to ensure code quality. There
                            are a few different code review practices, including
                            ad hoc review, pass-around, walkthrough, pull
                            request, inspection, and code quality metrics.
                            <br></br>
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default Java;
// 303
